<template>
    <form class="container" role="form" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-12">
          <b-form-group id="type" label-cols-sm="2" label-cols-lg="2" :label="$t('form.permissions.fields.titleField')"
            label-for="type" :invalid-feedback="titleValidationMessage" :state="!isTitleValid ? false : null">
            <b-form-input id="type" v-model="item.title" @input="validateTitleField"
              :state="!isTitleValid ? false : null"></b-form-input>
          </b-form-group>
        </div>
        </div>
      <div class="row">
        <div class="col-lg-4" v-for="(item, index) in items" :key="index">
          <p class="va-title mb-2">{{ item.type }}</p>
          <div class="custom-control custom-checkbox mb-3" v-for="(permission, indexPermission) in item.permissions"
            :key="indexPermission">
            <input type="checkbox" class="custom-control-input" v-model="permission.value.selected"
              :id="item.id + '_' + permission.value.id" />
            <label class="custom-control-label" style="font-size:0.8rem!important"
              :for="item.id + '_' + permission.value.id">{{
                permission.value.type }}</label>
          </div>
        </div>
        <div class="col-lg-12">
          <b-button variant="primary" type="submit" :disabled="formValidationButton">{{ $t('buttons.save') }}</b-button>
          </div>
      </div>
    </form>
</template>
    
<script>
export default {
  data() {
    return {
      formValidationButton: true,
      isTitleValid: true,
      titleValidationMessage: null
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: {
          type: String,
          default: '',
        },
      }),
    },
    type: {
      type: String,
      default: ''
    },
    items: {
      type: Array
    }
  },
  watch: {
    'item'() {
      if (this.type != "CREATE") {
        const self = this
        self.validateTitleField();
        self.validateForm()
        console.log(this.item)
      }
    },
  },
  computed: {
    isFormValid() {
      return this.isTitleValid
    },
  },
  methods: {
    validateTitleField() {
      this.isTitleValid = this.item.title !== null && this.item.title.length > 0;
      this.titleValidationMessage = this.isTitleValid ? '' : this.$t('form.permissions.validation.titleMandatory');
      this.validateForm()
    },
    validateForm() {
      var isTitleValid = this.item.title !== null && this.item.title.length > 0;
      this.formValidationButton = !(isTitleValid);
    },
    submitForm() {
      if (this.isFormValid) {
        const json = JSON.stringify({
          title: this.item.title,
          items:this.items
        });
        this.$emit('data', json);
      }
    },
  },
  mounted() {
    if (this.type == "CREATE") {
      this.isTitleValid = true
      this.titleValidationMessage = ""
      this.formValidationButton = true
    }
  }
};
</script>